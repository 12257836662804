<template>
  <div id="app1">
    <div  @click="goPrev">
       <div class="header P_2">
        <img src="../assets/icon30.png" alt="">
        <span>企业经营能力指数</span>
      </div>
    </div>
    <div class="box">
      <img src="../assets/jingyingnenglibanner@2x.png" alt="">
      <div class="abso" style="padding:1rem 1.5rem;width:100%">
        <div class="txt1">企业经营能力指数</div>
        <div class="txt2">{{ Number( scoreItem.operational_capability_score).toFixed(1)}} <span>分</span></div>
        <div class="model1">
          <div class="title">指数解读</div>
          <div class="subTitle">企业经营能力指数是基于以下纬度生成</div>
          <div class="btns">
            <div class="btn2" @click="goDetail(3,'营运能力')" style="background-color:#E5E7FF">营运能力</div>
            <div class="btn2" @click="goDetail(3,'盈利能力')" style="background-color:#E5E7FF">盈利能力</div>
            <div class="btn2" @click="goDetail(3,'偿付能力')" style="background-color:#E5E7FF">偿付能力</div>
            <div class="btn2" @click="goDetail(3,'成长潜力')" style="background-color:#E5E7FF">成长潜力</div>
            <div class="btn2" @click="goDetail(3,'市场地位')" style="background-color:#E5E7FF">市场地位</div>
          </div>
          <div>
            <div class="title2">本次信用测评为：<span style="color:#0379FB">{{content2}}</span> </div>
            <div class="txt">{{content4}}</div>
          </div>
          <div class="title2" style="padding-bottom:1.3rem;">企业信用指数发展趋势为：<span style="color:#0063FF">平稳</span> </div>
        </div>
        <!--<div class="model2">
          <div>
            <div class="title">企业经营能力</div>
            <div class="txt">{{content2}}</div>
          </div>
          <div  id="main" style="width: 25rem; height:10rem;"></div>
           <img src="../assets/jingyingtoumingzhishuimag@2x.png" alt=""> 
        </div>-->
        <div class="model3">
          <div>
            <div class="title">评价说明</div>
            <div class="companyTitle title" style="margin-top:0;padding:1rem 0">
              <div class="dot" style="background-color:#0379FB"></div>
              <div style="margin-left:0.5rem; font-weight: 600;">{{content3}}</div> 
            </div>
            <div class="txt"> {{content1}}</div>
          </div>
        </div>
        <div style="height:5rem"></div>
      </div>
    </div>
    
    <div class="fixBtn"> 
      <div class="btn" @click="goPrev" style="width:100%;">返回 力度分</div>  
      <!-- <div class="btn MR_2" @click="goPrev">上一页</div>  
         -->
      <!-- <div class="btn" style="border-top-right-radius: 5rem;border-bottom-right-radius: 5rem;">详细信息</div>   -->
    </div>
  </div>
</template>
<script>
 import * as echarts from 'echarts';
  import {mapState,} from "vuex"
export default {
  mounted(){
    let {
      credit_quality_score,
      operational_capability_level,
      viability_score,
      transparent_operation_score,
      ecological_chain_health_score,
      competition_score,
      operational_capability_score} = this.scoreItem
     let arr = [credit_quality_score,viability_score,transparent_operation_score,ecological_chain_health_score,competition_score,operational_capability_score].map(Number)
    //  this.initEacharts(arr)
     this.getContent(operational_capability_level)
  },
  data(){
    return {
      content1:"", //评价
      content2:"", //能力
      content3:"", //评价标题
      content4:""
    }
  },
   computed:{
    ...mapState({
      scoreItem:  (state) => state.scoreItem,
    }),
  },
  methods:{
    goDetail(type,content){
      this.$router.push({path: "/getLmxDesc",query:{
        type,
        content,
      }})
    },
    getContent(val){
      console.log(val)
      switch (val){
        case 'A':
            this.content1 = "运筹帷幄之中、决胜千里之外，该企业有极高的市场地位和极强的盈利能力，（位于行业top100）快投递简历吧～"  
            this.content4 = "企业经营实力极强，有很高的市场地位和盈利能力，为行业翘楚。" 
            this.content2 = "极好"
            this.content3 = "最强王者，行业翘楚。"  
            break;
        case 'B':
            this.content1 = "该企业高瞻远瞩，日渐壮大，（x年内市场规模扩大了x倍）有很强的经营发展能力，不要错过哦。"  
            this.content4 = "企业经营实力很强，交易量不断增长，日渐壮大。"  
            this.content2 = "优秀"
            this.content3 = "璀璨钻石，日渐壮大。"  
            break;
        case 'C':
            this.content1 = "该企业精益求精，不断发展创新，有良好的经营实力。"  
            this.content4 = "企业经营能力良好，积极达成盈利指标，未来可期。"
            this.content2 = "良好"  
            this.content3 = "华贵铂金，未来可期。"  
            break;
        case 'D':
            this.content1 = "该企业经营能力尚可，但还需观察其市场发展变化。"  
            this.content4 = "企业生存能力不错，仍需注意市场变化及竞争环境，风雨兼程。"  
            this.content2 = "中等"
            this.content3 = "荣耀黄金，风雨兼程。"  
          break;
         case 'E':
            this.content1 = "麻雀虽小五脏俱全，该企业在未来有很大发展空间。"  
            this.content4 = "经营不易，企业虽小五脏俱全，在未来有很大发展空间。" 
            this.content2 = "较差" 
            this.content3 = "坚韧黄铜，全力以赴。"  
          break;
        default:
        }
    },
    // 雷达图
    initEacharts(arr) {
      var myChart = echarts.init(document.getElementById('main'))
      var option;
      option = {
      radar: {
        indicator: [
            { name: '信用质量评分', max: 1000,   },
            { name: '生存能力评分', max: 1000,},
            { name: '企业透明指数', max: 1000, },
            { name: '生态链健康指数', max: 1000 },
            { name: '同业竞争评分', max: 1000 },
            { name: '经营能力评分', max: 1000,color:"#004CFF", }
        ],
        center: ['57%', '45%'],
        radius: 25,
      },
      series: [{
        type: 'radar',
        data: [
          {
            value: arr,
            areaStyle: {
              color: new echarts.graphic.RadialGradient(0.1, 0.6, 1, [
                {
                  color: 'rgba(3,121,251, 0.1)',
                  offset: 0 
                },
                {
                  color: 'rgba(3,121,251, 0.9)',
                  offset: 1
                }
              ])
            }
          }
      ]
      }]
    };
    myChart.setOption(option); 
    },
    goPrev(){
      this.$router.push("/reportDetail")
    },
  }
}
</script>
<style lang="scss" scoped>
#app1{
  background: #fff;
}
 .companyTitle{
  display: flex;
  align-items: center;
 }
.list{
  margin-top:2rem;
  display: flex;
  justify-content:space-around;
  align-items:flex-end;
  .item{
     
    .bar{
      width: 1rem;
      // height: 2rem;
       
      border-radius: 1rem;
      margin:0 auto;
    }
    .txt{
      width:2rem;
      margin-top:0.5rem;
    }
  }
}
</style>